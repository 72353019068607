import React, { useContext, useEffect, useState } from 'react'
import Navigation from '../../components/Navbar/Navigation'
import Footer from '../../components/Footer/Footer'
import BankCheckbox from '../../components/Banks/Banks'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from '../../App'
import { fetchingBookingSummary, requestPayment } from '../../services/ticketService'

const PackagePayment = () => {
    const {slug, bookingNumber} = useParams();
    const navigate = useNavigate();
    let [summaryData, setSummaryData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { selectDate, selectedBank, setSelectedBank, setTicketQr, method } = useContext(AppContext);
    let bank = selectedBank;
    function formatDate(dateString) {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('id-ID', options);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                if(bookingNumber === null){
                    navigate(`/package/${slug}`);
                }
                setSelectedBank(null);
                const summary = await fetchingBookingSummary(bookingNumber);
                fetchingBookingSummary(summary);
                setSummaryData(summary);
                console.log("summary:" ,summary);
                // setSummaryData(bankData.data);
            } catch (error) {
                if ((error.response && error.response.status === 401) || 403) {
                    localStorage.removeItem("user");
                    navigate("/login");
                } else {
                    // setError("Failed to fetch profile");
                    window.location.href = "/login";
                }
            }
        };
        fetchData();
    }, [bookingNumber]);
    return (
    <>
    <Navigation/>
          <div className="header"></div>
          <div className="bg-ticket">
              <h1>Payment</h1>
              <div className="bg-booking-va">
                  <div className="bg-white">
                      <h5>Summary</h5>
                      <div className="summary-bg">
                          <span>
                              Jumlah Tiket:{" "}
                              {summaryData && summaryData.data?.transaction.jumlah_ticket}
                          </span>
                          <span>
                              Total Harga: Rp{" "}
                              {summaryData && summaryData.data?.transaction.total_harga},-
                          </span>
                      </div>

                      <div className="summary-bg">
                          <span>
                              Nama: 
                              {summaryData && summaryData.data?.transaction.booker_name}
                          </span>
                          <span>
                              Email:
                              {summaryData && summaryData.data?.transaction.email}
                          </span>
                          <span>
                              No. Telepon:{" "}
                              {summaryData && summaryData.data?.transaction.phone}
                          </span>
                      </div>
                      <span className="info-va">
                          Pembayaran dapat dilakukan dengan metode pembayaran berikut.
                      </span>
                      <div className="banks-va">
                          <div className="payment-method">
                              {/* <span>Virtual Account</span> */}
                              <BankCheckbox />
                          </div>
                      </div>
                        <button
                            disabled={!selectedBank}
                            className="btn btn-book"
                            onClick={() =>
                                requestPayment(
                                    bookingNumber,
                                    method,
                                    bank,
                                    navigate,
                                    slug,
                                    setTicketQr
                                )
                            }
                        >
                            Book
                        </button>
                      <span className="need-info">
                          <a href="#">Butuh bantuan?</a>{" "}
                      </span>
                  </div>
              </div>
          </div>
    <Footer/>
    </>
    )
}

export default PackagePayment