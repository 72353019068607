import React, { useState, useEffect, useContext } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Navigation from "../../components/Navbar/Navigation";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { setProfile, clearProfile } from "../../features/profile";
import {
  fetchTicketTransaction,
  fetchTicketDetailTransaction,
} from "../../services/ticketService";
import { AppContext } from "../../App";
import Pagination from "../../components/Pagination/Pagination";
import BeatLoader from "react-spinners/BeatLoader";
Modal.setAppElement("#root");
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "block",
  },
};

const Profile = () => {
  const profile = useSelector((state) => state.profile.profile);
  const dispatch = useDispatch();

  const { ticketQrDetail, setTicketQrDetail } = useContext(AppContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tabClicked, setTabClicked] = useState(false); // New state variable
  const [tabClickedStay, setTabClickedStay] = useState(false); // New state variable
  const [tabClickedEvent, setTabClickedEvent] = useState(false); // New state variable

  // console.log("ticketQrDetail");
  // console.log(ticketQrDetail);
  // Pagination
  const [ticketUser, setTicketUser] = useState([]);
  const [accomodationtUser, setAccomodationUser] = useState([]);
  const [eventUser, setEventUser] = useState([]);
  const [ticketPerPage, setTicketPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await AuthService.getProfile();
        dispatch(setProfile(response.data.user));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setError("Failed to fetch profile");
          window.location.href = "/login";
        }
      }
    };
    fetchProfile();
  }, [dispatch]);

  const [payment, setPayment] = useState("paid");

  const handleClickTab = () => {
    // console.log("ticket");
    setTabClicked(true); // Set tabClicked to true when the tab is clicked
    setTabClickedStay(false); // Set tabClicked to true when the tab is clicked
    setTabClickedEvent(false); // Set tabClicked to true when the tab is clicked
  };

  const handleClickTabStay = () => {
    // console.log("stay");
    setTabClickedStay(true); // Set tabClicked to true when the tab is clicked
    setTabClicked(false); // Set tabClicked to true when the tab is clicked
    setTabClickedEvent(false); // Set tabClicked to true when the tab is clicked
  };

  const handleClickTabEvent = () => {
    console.log("event");
    setTabClickedEvent(true); // Set tabClicked to true when the tab is clicked
    setTabClickedStay(false); // Set tabClicked to true when the tab is clicked
    setTabClicked(false); // Set tabClicked to true when the tab is clicked
  };

  useEffect(() => {
    const fetchData = async () => {
      const type = "ticket";
      const response = await fetchTicketTransaction(
        type,
        payment,
        currentPage,
        50
      );
      if (response && response.data && response.data.transactions) {
        setTicketUser(response.data.transactions);
      }
      setTotalPages(response.data?.transactionRecap.totalPage);
    };
    fetchData();
  }, [payment, currentPage]);

  useEffect(() => {
    if (tabClicked) {
      const fetchData = async () => {
        const type = "ticket";
        const response = await fetchTicketTransaction(
          type,
          payment,
          currentPage,
          10
        );
        if (response && response.data && response.data.transactions) {
          setTicketUser(response.data.transactions);
        }
        setTotalPages(response.data?.transactionRecap.totalPage);
      };
      fetchData();
    }
  }, [tabClicked, payment, currentPage]);

  useEffect(() => {
    if (tabClickedStay) {
      const fetchData = async () => {
        const type = "stay";
        // const payment = "unpaid";
        const response = await fetchTicketTransaction(
          type,
          payment,
          currentPage,
          10
        );
        if (response && response.data && response.data.transactions) {
          setAccomodationUser(response.data.transactions);
        }
        setTotalPages(response.data?.transactionRecap.totalPage);
      };
      fetchData();
    }
  }, [tabClickedStay, payment, currentPage]);

  useEffect(() => {
    if (tabClickedEvent) {
      const fetchData = async () => {
        const type = "event";
        // const payment = "unpaid";
        const response = await fetchTicketTransaction(
          type,
          payment,
          currentPage,
          10
        );
        console.log("response event");
        console.log(response);
        if (response && response.data && response.data.transactions) {
          setEventUser(response.data.transactions);
        }
        setTotalPages(response.data?.transactionRecap.totalPage);
      };
      console.log("eventUser");
      console.log(eventUser);
      fetchData();
    }
  }, [tabClickedEvent, payment, currentPage]);

  const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    return (
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            disabled={currentPage === index + 1}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    );
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!profile) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This makes the div take the full viewport height
        }}
      >
        <BeatLoader />
      </div>
    );
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="profile section container ">
        <div className="detailProfileMaxWidth ">
          <div className="profile-detail">
            <div className="detail-info">
              <h1>Profile</h1>
              <h5 style={{ fontWeight: "bold" }}>Nama</h5>
              <p>{profile.name}</p>
              <h5 style={{ fontWeight: "bold" }}>Email</h5>
              <p>{profile.email}</p>
              <h5 style={{ fontWeight: "bold" }}>Telepon</h5>
              <p>{profile.phone}</p>
            </div>
            <hr />
            <div style={{ textAlign: "right" }}>
              <select
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
              >
                <option value="unpaid">Belum Dibayar</option>
                <option value="paid">Sudah Dibayar</option>
              </select>
            </div>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab onClick={() => handleClickTab()}>Tiket Masuk</Tab>
                <Tab onClick={() => handleClickTabStay()}>Akomodasi</Tab>
                <Tab onClick={() => handleClickTabEvent()}>Event</Tab>
                <Tab>Lainnya</Tab>
              </TabList>
              <TabPanels>
                {/* TAB TICKET */}
                <TabPanel>
                  <div className="detail-info">
                    {ticketUser.map((ticket, index) => (
                      <div className="ticketCard" key={index}>
                        <p>
                          Wahana:{" "}
                          {ticket.order_data &&
                            ticket.order_data.ticket?.ticket_name}
                        </p>
                        <p>No. Tiket: {ticket.booking_number}</p>
                        <p>Booker Name: {ticket.booker_name}</p>
                        <p>
                          Tanggal Tiket Masuk: {formatDate(ticket.checkin_date)}
                        </p>

                        {payment === "paid" ? (
                          <button
                            className="btn"
                            onClick={() => {
                              fetchTicketDetailTransaction(
                                ticket.booking_number,
                                setTicketQrDetail
                              );
                              openModal();
                            }}
                          >
                            QR Code
                          </button>
                        ) : (
                          <button
                            className="btn"
                            disabled={ticket.booking_status === 2}
                            onClick={() =>
                              navigate(
                                `/ticket-attraction/${ticket.order_data?.ticket?.slug}/payment/${ticket.booking_number}/pay`
                              )
                            }
                          >
                            {ticket.booking_status === 2 ? "Expired" : "Bayar"}
                          </button>
                        )}

                        <div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Ticket Details"
                            style={customStyles}
                          >
                            <h2>
                              {ticketQrDetail &&
                                ticketQrDetail.order_data &&
                                ticketQrDetail.order_data.ticket?.ticket_name}
                            </h2>
                            <p>
                              ID Ticket:{" "}
                              {ticketQrDetail && ticketQrDetail.booking_number}
                            </p>
                            <p>
                              Tanggal Tiket Masuk:{" "}
                              {ticketQrDetail && ticketQrDetail.checkin_date}
                            </p>
                            <p>
                              Jumlah Orang:{" "}
                              {ticketQrDetail && ticketQrDetail.jumlah_ticket}
                            </p>

                            <p>
                              QR Code:{" "}
                              {ticketQrDetail && ticketQrDetail.qr_code}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {ticketQrDetail.qr_path === null ? (
                                "QR Code Not Found"
                              ) : (
                                <img
                                  style={{
                                    maxWidth: "400px",
                                    marginBottom: "20px",
                                  }}
                                  src={ticketQrDetail && ticketQrDetail.qr_path}
                                  alt="qr"
                                />
                              )}

                              <button className="btn" onClick={closeModal}>
                                Close
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    ))}

                    {/* <Pagination
                      ticketUser={ticketUser.length}
                      ticketPerPage={ticketPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    /> */}
                  </div>
                </TabPanel>

                {/* STAY TAB */}
                <TabPanel>
                  <div className="detail-info">
                    {accomodationtUser.map((ticket, index) => (
                      <div className="ticketCard" key={index}>
                        <p>No. Tiket: {ticket.booking_number}</p>
                        <p>Booker Name: {ticket.booker_name}</p>
                        <p>Checkin: {formatDate(ticket.show.checkin)}</p>
                        <p>Checkout: {formatDate(ticket.show.checkout)}</p>

                        {payment === "paid" ? (
                          <button
                            className="btn"
                            onClick={() => {
                              fetchTicketDetailTransaction(
                                ticket.booking_number,
                                setTicketQrDetail
                              );
                              openModal();
                            }}
                          >
                            QR Code
                          </button>
                        ) : (
                          <button
                            className="btn"
                            disabled={ticket.booking_status === 2}
                            onClick={() =>
                              navigate(
                                `/accomodation/${ticket.order_data?.stayClass?.stay_slug}/booker-information/${ticket.booking_number}/payment`
                              )
                            }
                          >
                            {ticket.booking_status === 2 ? "Expired" : "Bayar"}
                          </button>
                        )}

                        <div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Ticket Details"
                            style={customStyles}
                          >
                            {/* <h2>
                          {ticketQrDetail &&
                            ticketQrDetail.order_data &&
                            ticketQrDetail.order_data.ticket.ticket_name}
                        </h2> */}
                            <p>
                              ID Ticket:{" "}
                              {ticketQrDetail && ticketQrDetail.booking_number}
                            </p>
                            {/* <p> */}
                              {/* Jumlah Room:{" "} */}
                              {ticketQrDetail &&
                                ticketQrDetail.order_data?.priceArr?.detail?.map(
                                  (room, index) => {
                                    return (
                                      <p key={index}>
                                        Tanggal: {room.date}, Jumlah Room:{" "}
                                        {room.room}
                                      </p>
                                    );
                                  }
                                )}
                            {/* </p> */}

                            <p>
                              QR Code:{" "}
                              {ticketQrDetail && ticketQrDetail.qr_code}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {ticketQrDetail.qr_path === null ? (
                                "QR Code Not Found"
                              ) : (
                                <img
                                  style={{
                                    maxWidth: "400px",
                                    marginBottom: "20px",
                                  }}
                                  src={ticketQrDetail && ticketQrDetail.qr_path}
                                  alt="qr"
                                />
                              )}

                              <button className="btn" onClick={closeModal}>
                                Close
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    ))}
                    {/* <Pagination
                      ticketUser={ticketUser.length}
                      ticketPerPage={ticketPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    /> */}
                  </div>
                </TabPanel>

                {/* TAB Event */}
                <TabPanel>
                  <div className="detail-info">
                    {eventUser.map((ticket, index) => (
                      <div className="ticketCard" key={index}>
                        <p>No. Tiket: {ticket.booking_number}</p>
                        <p>Booker Name: {ticket.booker_name}</p>
                        <p>Jumlah Ticket: {ticket.jumlah_ticket}</p>
                        <p>Event: {ticket.order_data.event.title}</p>
                        <p>
                          Category/Class: {ticket.order_data.event.class_title}
                        </p>
                        {/* <p>Checkout: {formatDate(ticket.show.checkout)}</p> */}

                        {payment === "paid" ? (
                          <button
                            className="btn"
                            onClick={() => {
                              fetchTicketDetailTransaction(
                                ticket.booking_number,
                                setTicketQrDetail
                              );
                              openModal();
                            }}
                          >
                            QR Code
                          </button>
                        ) : (
                          <button
                            className="btn"
                            disabled={ticket.booking_status === 2}
                            onClick={() =>
                              navigate(
                                `/events/${ticket.order_data?.stayClass?.stay_slug}/payment/${ticket.booking_number}/pay`
                              )
                            }
                          >
                            {ticket.booking_status === 2 ? "Expired" : "Bayar"}
                          </button>
                        )}

                        <div>
                          <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Ticket Details"
                            style={customStyles}
                          >
                            {/* <h2>
                          {ticketQrDetail &&
                            ticketQrDetail.order_data &&
                            ticketQrDetail.order_data.ticket.ticket_name}
                        </h2> */}
                            <p>
                              ID Ticket:{" "}
                              {ticketQrDetail && ticketQrDetail.booking_number}
                            </p>
                            <p>
                              {/* Jumlah Room:{" "} */}
                              {ticketQrDetail &&
                                ticketQrDetail.order_data?.priceArr?.detail?.map(
                                  (room, index) => {
                                    return (
                                      <p key={index}>
                                        Tanggal: {room.date}, Jumlah Room:{" "}
                                        {room.room}
                                      </p>
                                    );
                                  }
                                )}
                            </p>

                            <p>
                              QR Code:{" "}
                              {ticketQrDetail && ticketQrDetail.qr_code}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {ticketQrDetail.qr_path === null ? (
                                "QR Code Not Found"
                              ) : (
                                <img
                                  style={{
                                    maxWidth: "400px",
                                    marginBottom: "20px",
                                  }}
                                  src={ticketQrDetail && ticketQrDetail.qr_path}
                                  alt="qr"
                                />
                              )}

                              <button className="btn" onClick={closeModal}>
                                Close
                              </button>
                            </div>
                          </Modal>
                        </div>
                      </div>
                    ))}
                    {/* <Pagination
                      ticketUser={ticketUser.length}
                      ticketPerPage={ticketPerPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    /> */}
                  </div>
                </TabPanel>

                <TabPanel>{/* <p>three!</p> */}</TabPanel>
              </TabPanels>
            </Tabs>
          </div>
          <Pagination
            className="pagination"
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </section>
    </>
  );
};

export default Profile;
